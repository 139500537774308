import { isGetStartedRoute, isDirectMailerRoute } from 'utils/validations/isRoutes'

/**
 * Adds UTM parameters to a link url if it matches any of the routes in the condition
 *
 * @param {string} memoHref - The memo href to add UTM parameters to.
 * @returns {string} The memo href with added UTM parameters.
 */
const getProductAndAddDefaultUtmsToLink = (memoHref) => {
  // If the memo href is a get started link, add UTM parameters.
  if (
    isGetStartedRoute(memoHref?.replace('/', '').substring(0, 11)) ||
    isDirectMailerRoute(memoHref)
  ) {
    memoHref = addDefaultOrganicParams(memoHref)

    // Add utm_source parameter if it does not exist
    if (memoHref.indexOf('utm_source') === -1) {
      let product
      product = memoHref
        ?.split('?')[1]
        ?.split('&')
        ?.filter((qp) => qp.split('=')[0] == 'section')[0]
        ?.split('=')[1]
        .split('_')[1]
      memoHref = `${memoHref}&utm_source=achieve_organic_${product}`
    }
  }

  return memoHref
}

/**
 * Adds default UTM parameters to a link url
 *
 * @param {string} memoHref - The memo href to add UTM parameters to.
 * @returns {string} The memo href with added UTM parameters.
 */
const addDefaultOrganicParams = (memoHref) => {
  const hasExistingParams = Boolean(memoHref.indexOf('?') !== -1)

  if (memoHref.indexOf('utm_medium') === -1) {
    memoHref = `${memoHref}${hasExistingParams ? '&' : '?'}utm_medium=organic`
  }

  // Add utm_media parameter if it does not exist or if utm_term also exists.
  if (memoHref.indexOf('utm_media') === -1 && memoHref.indexOf('utm_term') === -1) {
    memoHref = `${memoHref}&utm_media=organic`
  }

  return memoHref
}

export { addDefaultOrganicParams, getProductAndAddDefaultUtmsToLink }
